import AuthService from '../domain/AuthService';
import ErrorHandler from '../error/ErrorHandler';
import LinksProvider from '../http/LinksProvider';
import SavedActionsService from '../utils/SavedActionsService';
import {useToasts} from "react-toast-notifications";
import React, {useEffect, useState} from "react";
import {withTranslation} from "../../i18n";
import WithGA from "./withGa";
import cookies from 'next-cookies';
import CookiesBanner from "../../shared/cookies/cookies-banner/CookiesBanner";
import Router from "next/router";

const redirect = (ctx, url) => {
  if (ctx && ctx.res) {
    ctx.res.writeHead(302, {
      Location: url
    });
    // server side (context exists)
    return ctx.res.end();
  } else {
    // client side
    Router.replace(url)
  }
};

export const withPageWrapper = ({authenticated, namespacesRequired, hasAccess} = {}) => {
  return WrappedComponent => {
    const Wrapper = props => {
      const {addToast} = useToasts();
      const [isAnalyticCookiesActive] = useState(null);

      useEffect(() => {
        ErrorHandler.displayCookieErrorMessages(props.errorMessages, addToast, props.t);
        ErrorHandler.displayCookieSuccessMessages(props.successMessages, addToast, props.t);
      }, []);

      return (
        <React.Fragment>

          <WithGA
            content={<WrappedComponent {...props} />}/>

          <CookiesBanner/>

        </React.Fragment>

      );
    };

    Wrapper.getInitialProps = async ctx => {
      const principal = AuthService.getPrincipal(ctx);
      const allCookies = cookies(ctx);

      if (authenticated && !principal) {
        SavedActionsService.saveRedirectUrl(ctx, ctx.req.path);
        return redirect(ctx, LinksProvider.ROUTES.AUTH.SIGN_IN);
      }
      
      //Extract cookies messages
      const errorMessages = ErrorHandler.getCookieErrorMessages(ctx);
      const successMessages = ErrorHandler.getCookieSuccessMessages(ctx);

      let componentProps;
      try {
        componentProps = WrappedComponent.getInitialProps
          && (await WrappedComponent.getInitialProps(ctx));
      } catch (e) {
        if (e && e.code && e.code === 'E_UNAUTHORIZED') {
          return redirect(ctx, LinksProvider.ROUTES.AUTH.SIGN_IN);
        }
      }

      //redirect if the user is not connected and the page is protected
      if (componentProps && ctx && ctx.res) {
        if (authenticated && !principal) {
          SavedActionsService.saveRedirectUrl(ctx, ctx.req.path);
          return redirect(ctx, LinksProvider.ROUTES.AUTH.SIGN_IN);
        } else if (hasAccess && !hasAccess(componentProps, ctx)) {
          return;
        }
      }

      return {...componentProps, principal, errorMessages, successMessages, allCookies};
    };

    return withTranslation(namespacesRequired)(Wrapper);
  };
};
