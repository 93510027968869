/**
 * SavedActionsService
 * @module Services/utils/SavedActionsService
 * @description Offers utility functions to handle saved actions
 */

import cookies from 'js-cookie';
import {setCookie} from 'nookies';

const self = {};

const POT_CREATION_DATA_TAG = 'pot_data';
const REDIRECT_URL_TAG = 'redirect_url';

const SAVED_ACTION_TIMEOUT_MS = 30 * 1000 * 60; // 30 minutes

/**
 * Saves data into browser cookie
 * @author Sameh Bellez
 * @alias saveDataClientSide
 * @memberof module:Services/utils/SavedActionsService
 * @param   {string} tag The cookie tag
 * @param   {object} data The data to be saved
 */
const saveDataClientSide = (tag, data) => {
  const dataToSave = {timestamp: new Date().getTime(), data: data};
  cookies.set(tag, JSON.stringify(dataToSave));
};

/**
 * Gets data from browser cookies
 * @author Sameh Bellez
 * @alias getDataClientSide
 * @memberof module:Services/utils/SavedActionsService
 * @param   {string} tag The cookie tag
 */
const getDataClientSide = (tag) => {
  const str = cookies.get(tag);
  cookies.remove(tag);

  if (!str) {
    return null;
  }

  const data = JSON.parse(str);

  if (new Date().getTime() - data.timestamp > SAVED_ACTION_TIMEOUT_MS) {
    return null;
  }

  return data.data;
};

/**
 * Saves data into server cookie
 * @author Sameh Bellez
 * @alias saveDataServerSide
 * @memberof module:Services/utils/SavedActionsService
 * @param   {string} tag The cookie tag
 * @param   {object} data The data to be saved
 */
const saveDataServerSide = (ctx, tag, data) => {
  const dataToSave = {timestamp: new Date().getTime(), data: data};
  setCookie(ctx, tag, JSON.stringify(dataToSave), {
    path: '/',
  });
};

/**
 * Saves pot creation data into cookie
 * @author Sameh Bellez
 * @alias savePotCreationData
 * @memberof module:Services/utils/SavedActionsService
 * @param   {object} data The data to be saved
 */
self.savePotCreationData = (data) => {
  saveDataClientSide(POT_CREATION_DATA_TAG, data);
};

/**
 * Gets pot creation data from cookies
 * @author Sameh Bellez
 * @alias getPotCreationData
 * @memberof module:Services/utils/SavedActionsService
 * @return {object} the found data
 */
self.getPotCreationData = () => {
  return getDataClientSide(POT_CREATION_DATA_TAG);
};

/**
 * Sets the next redirect url in cookies
 * @name SavedActionsService.saveRedirectUrl
 * @author Sameh Bellez
 * @alias saveRedirectUrl
 * @memberof Services/utils/SavedActionsService
 * @param   {ctx} Next js context
 * @param   {string} The url to be stored
 */
self.saveRedirectUrl = (ctx, url) => {
  saveDataServerSide(ctx, REDIRECT_URL_TAG, url);
};

/**
 * Sets the next redirect url in cookies
 * @name SavedActionsService.getRedirectUrl
 * @author Sameh Bellez
 * @alias getRedirectUrl
 * @memberof Services/utils/SavedActionsService
 * @return {string} the redirect url
 */
self.getRedirectUrl = () => {
  return getDataClientSide(REDIRECT_URL_TAG);
};

export default self;