import React, {useEffect} from 'react';
import AnalyticsService from "./AnalyticsService";
import {useRouter} from 'next/router';
import Routes from '../../routes';

const WithGA = ({content}) => {
  const router = useRouter();

  useEffect(() => {
    if (!AnalyticsService.isAnalyticsActive()) {
      return;
    }

    AnalyticsService.enableAnalyticsCookie();
    const pathname = router.pathname;
    const {routes} = Routes;
    const route = routes.filter(route => route.page === pathname)[0];

    if (!route) {
      console.error(`Path name ${pathname} cannot be found in the declared routes`);
    }
  }, []);

  return (<div>
    {content}
  </div>);
};

export default WithGA;

